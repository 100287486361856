export const initUtilities = () => {

    // FAQ Questions
    $(document).on('click', '.faq-question', function () {
        $(this).toggleClass('open');
        $(this).next().slideToggle(200);
    });

    $(document).on('keydown', '.faq-question', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });

    // Location Tabs
    $(document).on('click', '.tab-link', function () {
        let location = $(this).data('location');
        $('.tab-link, .detail').removeClass('is-active');
        $(this).addClass('is-active');
        $('#'+location).addClass('is-active');
    })

    $(document).on('keydown', '.tab-link', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });


    // locations scroll
    $(document).on('click', '.tab-link', function () {
        $('html, body').animate({
            scrollTop: $('#location-detail').offset().top - 80
        }, 200);
    })
    

    // Map view toggle
    $(document).on('click', '.icon-button', function () {
        $('#list-view, #map-view, .white-gradient').hide();
        $('.icon-button').removeClass('is-active');
        $(this).addClass('is-active');
        let view = $(this).data('view')
        $('#'+view).show();
        if(view === 'map-view'){
            $('.white-gradient').show();
        }
    })

    $(document).on('keydown', '.icon-button', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });

    // province clicks
    $(document).on('click', '.province-click', function () {
        let province = $(this).data('province');
        let province_label = $(this).data('province-label');
        $('.province-location').hide();
        $('#location-panel .province-label').text(province_label);
        $('#province-' + province + ', #location-panel').fadeIn(200);
    });

    $(document).on('keydown', '.province-click', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });

    // only with tab key
    $(document).on('keydown', function (e) {
        if (e.key === 'Tab') {
            $(document).on('focus', '.province-click', function (e) {
                e.stopPropagation();
                $(window).scrollTop($(this).offset().top - 500);
            });
        }
    });

    $(document).on('click', '#close-location', function () {
        $('.province-location, #location-panel').hide();
    })

    $(document).on('keydown', function (e) {
    if (e.key === 'Escape') {
        $('#close-location').trigger('click');
    }
});


}
export default initUtilities;