// uncomment for development only, turns on HMR
// import '/src/sass/app.scss';

import $ from 'jquery';
import initSliders from './sliders';
import initMenuFunctions from "./menus";
import initAnimations from "./animations";
import initUtilities from "./utils";

$(document).ready(function () {

    // initialize animations
    initAnimations();

    // initialize slider carousels
    initSliders();

    // initialize menu functions
    initMenuFunctions();

    // initialize the utility functions
    initUtilities();
})