import $ from 'jquery'

export const initMenuFunctions = () => {
    //init menu
    initMenuSizing()
};

function initMenuSizing(){

    // mobile menu
    $(document).on('click', '.hamburger', function () {
        $(this).toggleClass('is-active');
        $('.mobile-menu').toggleClass('open');
        setTimeout(() => {
            $('.mobile-menu .main-menu, .mobile-menu .search-form').toggleClass('fade-in');
        }, 300);
    });

    if(window.innerWidth < 1191){
    // mobile slide-ups and downs
        $(document).on('click', '#mobile-navigation .has-children, #sticky-navigation .has-children', function (e) {
            if ($(this).children('ul').hasClass('open')) {
                $(this).children('ul').removeClass('open').slideUp(200);
            } else {
                $('.has-children ul').removeClass('open').slideUp(200);
                $(this).children('ul').addClass('open').slideDown(200);
            }
        });
    }

    // search button
    $(document).on('click', '.search-btn', function () {
        $('.megamenu').removeClass('show');
        $('#search').fadeToggle(200);
        $(this).toggleClass('active');
        $('#search input').focus();
    });

    // megamenu
    $(document).on('click', '#desktop-navigation .has-children, #sticky-navigation .has-children', function (e) {
        e.preventDefault();
        $('.search-btn').removeClass('active');
        $('#search').fadeOut(0);

        let nodeId = $(this).data('submenu');
        let $submenu = $('#' + nodeId);

        if ($submenu.hasClass('show')) {
            $submenu.removeClass('show');
        } else {
            $('.megamenu').removeClass('show');
            $submenu.addClass('show');
        }
    });

    // close megamenu
    $(document).on('click', '.closeMegamenu', function (e) {
        e.stopPropagation();
        $('.megamenu').removeClass('show');
    });

    // close megamenu on click outside
    $(document).on('click', function (e) {
        if (!$(e.target).closest('.megamenu').length && !$(e.target).closest('.has-children').length) {
            $('.megamenu').removeClass('show');
        }
    });

    // close megamenu with esc key
    $(document).keyup(function (e) {
        if (e.keyCode === 27 && $('.megamenu').hasClass('show')) {
            e.preventDefault();
            $('.megamenu').removeClass('show');
        }
        if (e.keyCode === 27 && $('#search').is(':visible')) {
            e.preventDefault();
            $('#search').fadeOut(200);
            $('.search-btn').removeClass('active');
        }
    });

    // sticky menu scroll effect
    let lastScrollTop = 0;
    $(window).scroll(function () {
        let st = $(this).scrollTop();
        if (st > 175) {
            if($('.sticky-menu').hasClass('show')) return;
            $('.sticky-menu').addClass('show');
        } else {
            if(!$('.sticky-menu').hasClass('show')) return;
            $('.sticky-menu').removeClass('show');
            if(!$('.megamenu').hasClass('show')) return;
            $('.megamenu').removeClass('show');
        }
        lastScrollTop = st;
    });
}

export default initMenuFunctions;